<template>
  <div class="modal modal__keyword" v-if="openAddKeywordModal.status == true">
    <div class="modal__wrapper">
      <div class="card">
        <div class="modal__title">
          <template v-if="openAddKeywordModal.action == 'add'"
            >+ {{ $t("list.btn_add_keyword") }}</template
          >
          <template v-if="openAddKeywordModal.action == 'edit'">
            <font-awesome-icon :icon="['fa', 'pencil-alt']" />
            {{ $t("adding.edit") }} {{ $t("adding.keyword") }}
          </template>
          <template v-if="openAddKeywordModal.action == 'clone'">
            <font-awesome-icon :icon="['far', 'clone']" />
            {{ $t("adding.clone") }} {{ $t("adding.keyword") }}
          </template>
        </div>
        <div class="modal__text">
          <div class="form-group">
            <label>{{ $t("adding.enter_keyword") }}</label>
            <input
              pattern="[A-Za-z0-9\,\-]{1,30}"
              type="text"
              ref="keywordInput"
              v-model="newKeyword.name"
            />
          </div>

          <div class="form-group">
            <label
              >{{ $t("adding.exclude_keywords") }} ({{
                $t("adding.optional")
              }})</label
            >
            <vue-tags-input
              :add-on-key="[13, ':', ';', ',']"
              :placeholder="$t('adding.type_and_press_enter')"
              v-model="currentStopWord"
              :tags="newKeyword.stopwords"
              @tags-changed="(newTags) => updateStopWords(newTags)"
            />
          </div>
          <div class="form-group">
            <label>{{ $t("adding.category") }}</label>
            <treeselect
              v-model="newKeyword.categories"
              :multiple="false"
              :options="processedCategories"
              :searchable="false"
              :noChildrenText="'No child categories'"
            />
          </div>
          <div class="form-group">
            <label>{{ $t("adding.interval") }}</label>
            <select
              name=""
              id=""
              v-model="newKeyword.rate"
              :disabled="newKeyword.isFast == true"
            >
              <option
                v-for="r in rates"
                :value="r"
                :key="r"
                :disabled="!checkIfKeywordRateAllowed(r)"
                :selected="newKeyword.rate == r"
              >
                <template v-if="r < 60">{{ r }} s.</template>
                <template v-else> {{ r / 60 }} min.</template>
              </option>
            </select>
          </div>
          <div class="form-group" v-if="plan && allowFast">
            <label
              >⚡{{ $t("adding.fast_keyword") }} ({{
                plan.fast_keywords_rate
              }}s.)</label
            >
            <div class="ui-checkbox">
              <label>
                <input
                  type="checkbox"
                  :checked="newKeyword.isFast"
                  @change="toggleFastKeyword()"
                />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>Search short description</label>
            <div class="ui-checkbox">
              <label>
                <input
                  type="checkbox"
                  v-model="newKeyword.itemShortDescription"
                />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t("adding.checkout_link") }}</label>
            <div class="ui-checkbox">
              <label>
                <input
                  type="checkbox"
                  v-model="newKeyword.includeQuickBuyLink"
                />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="form-group" v-if="plan && plan.buyButton == true">
            <label>Buy button</label>
            <div class="ui-checkbox">
              <label>
                <input type="checkbox" v-model="newKeyword.buyButton" />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t("adding.all_images") }}</label>
            <div class="ui-checkbox">
              <label>
                <input type="checkbox" v-model="newKeyword.allImages" />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="form-group" v-show="!newKeyword.allImages">
            <label>{{ $t("adding.extra_images") }} </label>
            <div class="ui-radio-group">
              <label v-for="(n, i) in 4" :key="i">
                <input
                  type="radio"
                  :value="i"
                  checked
                  v-model="newKeyword.extraImages"
                />
                <span>{{ i }}</span>
              </label>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t("adding.buying_format") }}</label>
            <div class="ui-radio-group">
              <label>
                <input
                  type="radio"
                  value="0"
                  checked
                  v-model="newKeyword.buyingFormat"
                />
                <span>{{ $t("adding.all") }}</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="1"
                  v-model="newKeyword.buyingFormat"
                />
                <span>Auction</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="2"
                  v-model="newKeyword.buyingFormat"
                />
                <span>Buy It Now</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="3"
                  v-model="newKeyword.buyingFormat"
                />
                <span>Best Offer</span>
              </label>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t("adding.condition") }}</label>
            <div class="ui-radio-group">
              <label>
                <input
                  type="radio"
                  value="0"
                  checked
                  v-model="newKeyword.condition"
                />
                <span>{{ $t("adding.any") }}</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="1000"
                  v-model="newKeyword.condition"
                />
                <span>New</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="3000"
                  v-model="newKeyword.condition"
                />
                <span>Used</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="7000"
                  v-model="newKeyword.condition"
                />
                <span>Parts</span>
              </label>
            </div>
          </div>
          <div class="form-group keyword-price">
            <div class="w-50">
              <label>
                {{ $t("adding.price") }} - $
                <strong>[{{ $t("adding.min") }}]</strong>
              </label>
              <input type="tel" v-model="newKeyword.priceMin" placeholder="0" />
            </div>
            <div class="w-50">
              <label>
                {{ $t("adding.price") }} - $
                <strong>[{{ $t("adding.max") }}]</strong>
              </label>
              <input
                type="tel"
                v-model="newKeyword.priceMax"
                :placeholder="$t('adding.any_f')"
              />
            </div>
          </div>
        </div>
        <div class="modal__controls">
          <button-primary
            :onClick="emitClose"
            :class="'small secondary black'"
            >{{ $t("adding.cancel") }}</button-primary
          >
          <button-primary
            :onClick="
              () => {
                emitSave(openAddKeywordModal.action);
              }
            "
            :disabled="newKeyword.name.length < 2"
            :class="'small'"
          >
            <span v-if="!updateLoading">{{ $t("adding.save") }}</span>
            <font-awesome-icon
              v-if="updateLoading == true"
              :icon="['fas', 'circle-notch']"
              spin
            />
          </button-primary>
        </div>
      </div>
    </div>
  </div>
  <!-- /addingKeywordModal -->
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import VueTagsInput from "@johmun/vue-tags-input";
export default {
  props: ["openAddKeywordModal", "plan", "processedCategories", "newKeyword"],
  data() {
    return {
      currentStopWord: "",
      updateLoading: false,
      rates: [1, 10, 30, 60, 120, 300, 600, 900, 1800, 3600],
    };
  },
  watch: {
    openAddKeywordModal: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.status == true) {
          this.$nextTick(() => {
            this.$refs.keywordInput.focus();
          });
        }
      },
    },
  },
  computed: {
    allowedRates() {
      var allowed = [];
      for (var i = 0; i < this.rates.length; i++) {
        if (this.rates[i] >= this.plan.keywords_rate) {
          allowed.push(this.rates[i]);
        }
      }
      return allowed;
    },
    allowFast() {
      var allow = false;
      var fastCount = 0;
      if (this.userKeywords) {
        for (var key in this.userKeywords) {
          if (this.userKeywords[key].isFast) {
            fastCount += 1;
          }
        }
      }
      if (
        this.plan &&
        this.plan.fast_keywords_count &&
        Number(this.plan.fast_keywords_count) > 0 &&
        fastCount < Number(this.plan.fast_keywords_count)
      ) {
        allow = true;
      }
      return allow;
    },
  },
  components: {
    Treeselect,
    VueTagsInput,
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    emitSave(action) {
      this.$emit("save", { action: action, newKeyword: this.newKeyword });
    },
    updateStopWords(newTags) {
      this.newKeyword.stopwords = [];
      for (var i = 0; i < newTags.length; i++) {
        const re = /[^\x20-\x7E]+/g;
        var item = newTags[i].text.replace(re, "");

        this.newKeyword.stopwords.push(item);
      }
    },
    doubleCheckKeywordRate(rate) {
      var finalRate = this.plan.keywords_rate;
      if (
        this.newKeyword.isFast &&
        Number(rate) == Number(this.plan.fast_keywords_rate)
      ) {
        finalRate = rate;
      } else if (!this.newKeyword.isFast && this.allowedRates.includes(rate)) {
        finalRate = rate;
      } else {
        finalRate = this.allowedRates[0];
      }
      return finalRate;
    },
    checkIfKeywordRateAllowed(rate) {
      var allowed = true;

      if (this.allowedRates.includes(rate) == false) {
        allowed = false;
      }

      return allowed;
    },
  },
};
</script>

<style lang="scss" scoped></style>
