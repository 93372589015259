<template>
  <div class="sellersList">
    <div class="nav-tabs">
      <a
        href="#"
        @click="changeView('all')"
        class="item"
        v-bind:class="{ active: view == 'all' }"
        >{{ $t("list.all_tab") }}</a
      >
      <a
        href="#"
        @click="changeView('seller')"
        class="item"
        v-bind:class="{ active: view == 'seller' }"
        >{{ $t("list.sellers_tab") }}</a
      >
      <a
        href="#"
        @click="changeView('keyword')"
        class="item"
        v-bind:class="{ active: view == 'keyword' }"
        >{{ $t("list.keywords_tab") }}</a
      >
    </div>
    <template v-if="isLoading">
      <content-loader
        :height="100"
        :width="400"
        :speed="2"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="22" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="44" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="64" rx="0" ry="0" width="100%" height="14" />
        <rect x="0" y="86" rx="0" ry="0" width="100%" height="14" />
      </content-loader>
    </template>
    <template v-if="!isLoading">
      <div
        class="tabs-content"
        :class="{ loading: updateLoading }"
        v-if="userItemsList.length > 0"
      >
        <div class="loading-overlay" v-if="updateLoading">
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
        </div>
        <div class="table-head">
          <div class="order"></div>
          <div class="name">
            <strong class="clickable" @click="sortParam = 'name'">
              {{ $t("list.title_col") }}
              <font-awesome-icon
                v-if="sortParam == 'name'"
                :icon="['fa', 'caret-down']"
              />
            </strong>
          </div>
          <div class="note">
            <strong>{{ $t("list.note_col") }}</strong>
          </div>
          <div class="type">
            <strong class="clickable" @click="sortParam = 'type'">
              {{ $t("list.type_col") }}
              <font-awesome-icon
                v-if="sortParam == 'type'"
                :icon="['fa', 'caret-down']"
              />
            </strong>
          </div>
          <div class="status">
            <strong>{{ $t("list.status_col") }}</strong>
          </div>
          <div class="added">
            <strong class="clickable" @click="sortParam = 'added'">
              {{ $t("list.added_col") }}
              <font-awesome-icon
                v-if="sortParam == 'added'"
                :icon="['fa', 'caret-down']"
              />
            </strong>
          </div>
          <div class="rate">
            <strong>{{ $t("list.rate_col") }}</strong>
          </div>
          <div class="controls">
            <strong>{{ $t("list.actions_col") }}</strong>
          </div>
        </div>
        <div
          class="table-row"
          v-for="(item, i) in filteredList"
          v-bind:key="item.uid"
          v-bind:class="{ disabled: item.status == 0 }"
        >
          <div class="order">{{ i + 1 }}</div>
          <div class="name">
            <span :class="{ keyword: item.type == 'keyword' }">{{
              item.name
            }}</span>
          </div>
          <div class="note">
            <font-awesome-icon
              :class="'note-icon'"
              v-tooltip="'Add note'"
              @click="noteModal(item.uid, item.name, item.type, item.note)"
              v-if="!item.note"
              :icon="['fa', 'sticky-note']"
            />
            <font-awesome-icon
              :class="'info-icon'"
              v-tooltip="item.note"
              @click="noteModal(item.uid, item.name, item.type, item.note)"
              v-if="item.note"
              :icon="['fa', 'info-circle']"
            />
          </div>
          <div class="type">
            <span>{{ item.type }}</span>
          </div>
          <div class="status">
            <div class="ui-checkbox">
              <label>
                <input
                  @change="quickEdit(item.type, item.uid)"
                  type="checkbox"
                  :checked="item.status == 1"
                  v-model="item.status"
                  :disabled="isLoading"
                  true-value="1"
                  false-value="0"
                />
                <span>
                  <i></i>
                </span>
              </label>
            </div>
          </div>
          <div class="added">
            {{ item.added | dateAdded | moment("from", "now") }}
          </div>
          <div class="rate">
            <span>
              <font-awesome-icon :icon="['fa', 'stopwatch']" />
              {{ item.rate }} {{ $t("global.sec") }}
            </span>
          </div>
          <div class="controls">
            <a
              href="#"
              class="edit"
              v-if="item.type == 'seller'"
              v-tooltip="'Edit seller'"
              @click.prevent="openSellerEditingModal(item.uid)"
            >
              <font-awesome-icon :icon="['fa', 'pencil-alt']" />
            </a>
            <a
              href="#"
              class="edit"
              v-if="item.type == 'keyword'"
              v-tooltip="'Edit keyword'"
              @click.prevent="openAddingModal('keyword', 'edit', item.uid)"
            >
              <font-awesome-icon :icon="['fa', 'pencil-alt']" />
            </a>
            <a
              href="#"
              class="clone"
              v-if="item.type == 'keyword'"
              v-tooltip="'Clone keyword'"
              @click.prevent="openAddingModal('keyword', 'clone', item.uid)"
            >
              <font-awesome-icon :icon="['far', 'clone']" />
            </a>
            <a
              v-bind:href="'https://www.ebay.com/usr/' + item.name"
              v-tooltip="'Open in new tab'"
              class="link"
              target="_blank"
              v-if="item.type == 'seller'"
            >
              <font-awesome-icon :icon="['fas', 'external-link-alt']" />
            </a>
            <a
              href="#"
              class="remove"
              v-tooltip="'Remove'"
              @click.prevent="openRemovingModal(item.uid)"
            >
              <font-awesome-icon :icon="['fa', 'trash']" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="no-items"
        v-if="user.profile && user.profile.telegram !== 'true' && !hasItems"
      >
        <div
          class="text-center"
          style="max-width: 100%; width: 500px; margin: 0px auto"
        >
          <div class="image" style="margin-bottom: 10px">
            <img src="@/assets/no_messenger.svg" height="160" alt />
          </div>
          <h2>
            <strong>{{ $t("list.welcome") }}!</strong>
          </h2>
          {{ $t("list.no_messengers") }}
          <br />
          <br />
          {{ $t("list.added_refresh") }}
          <div>
            <router-link
              to="/notifications"
              class="button small"
              style="margin-top: 30px"
            >
              <font-awesome-icon
                :icon="['fa', 'envelope']"
                style="margin-right: 10px"
              />
              {{ $t("menu.notifications") }}
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="no-items"
        v-if="
          userItemsList.length === 0 &&
            user.profile &&
            user.profile.telegram == 'true'
        "
      >
        <div class="text-center">
          {{ $t("list.no_items") }}
          <br />
          {{ $t("list.start_adding") }}
          <div class="image">
            <img src="@/assets/add_items.svg" height="96" alt />
          </div>
        </div>
      </div>
      <div class="tabs-controls">
        <template v-if="!isLoading && view == 'all'">
          <button-primary
            :onClick="
              () => {
                addContextOpen = !addContextOpen;
              }
            "
            :class="'small'"
            :disabled="
              user.profile &&
                user.profile.telegram &&
                user.profile.telegram !== 'true' &&
                !hasItems
            "
          >
            + {{ $t("list.btn_add") }}
            <font-awesome-icon :icon="['fa', 'caret-down']" />
            <context-menu v-show="addContextOpen == true" :position="'left'">
              <a
                href
                @click.prevent="
                  () => {
                    openAddingModal('seller');
                  }
                "
                >{{ $t("list.btn_add_seller") }}</a
              >
              <a
                href
                @click.prevent="
                  () => {
                    openAddingModal('keyword', 'add');
                  }
                "
                >{{ $t("list.btn_add_keyword") }}</a
              >
            </context-menu>
          </button-primary>
        </template>
        <template v-if="!isLoading && view == 'seller'">
          <button-primary
            :onClick="
              () => {
                openAddingModal('seller');
              }
            "
            :class="'small'"
            :disabled="
              user.profile &&
                user.profile.telegram &&
                user.profile.telegram !== 'true' &&
                !hasItems
            "
            >+ {{ $t("list.btn_add_seller") }}</button-primary
          >
        </template>
        <template v-if="!isLoading && view == 'keyword'">
          <button-primary
            :onClick="
              () => {
                openAddingModal('keyword', 'add');
              }
            "
            :class="'small'"
            :disabled="
              user.profile &&
                user.profile.telegram &&
                user.profile.telegram !== 'true' &&
                !hasItems
            "
            >+ {{ $t("list.btn_add_keyword") }}</button-primary
          >
        </template>
      </div>
    </template>

    <addSellerPopup
      :open="openAddSellerModal"
      :plan="plan"
      :edit="editingSeller"
      :newSeller="newSeller"
      :processedCategories="processedCategories"
      @close="closeAddingModal"
      @save="saveSeller"
    />
    <addKeywordPopup
      :openAddKeywordModal="openAddKeywordModal"
      :plan="plan"
      :newKeyword="newKeyword"
      :processedCategories="processedCategories"
      @close="closeAddingModal"
      @save="saveKeyword"
    />

    <div class="modal" v-if="openRemoveModal == true">
      <div class="modal__wrapper">
        <div class="card">
          <div class="modal__title">{{ $t("adding.remove") }} ?</div>
          <div class="modal__text">
            <strong>{{ itemToRemoveName }}</strong>
            {{ $t("adding.removed") }}
          </div>
          <div class="modal__controls">
            <button-primary
              :onClick="closeRemovingModal"
              :class="'small secondary black'"
              >{{ $t("adding.cancel") }}</button-primary
            >
            <button-primary
              :onClick="
                () => {
                  removeItem(itemToRemoveType);
                }
              "
              :class="'small delete'"
            >
              <span v-if="!updateLoading">{{ $t("adding.remove") }}</span>
              <font-awesome-icon
                v-if="updateLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
          </div>
        </div>
      </div>
    </div>
    <!-- /removingModal -->

    <reached-limit-popup
      :showReachedLimit="showReachedLimit"
      @close="showReachedLimit = false"
    />
    <div class="modal modal_note" v-if="openNoteModal == true">
      <div class="modal_wrapper">
        <div class="card">
          <div class="modal__title">
            {{ $t("adding.note") }} {{ $t("adding.to") }} {{ note.type }}:
            {{ note.name }}
          </div>
          <div class="modal__text">
            <textarea
              :maxlength="note.maxlength"
              v-model="note.text"
              rows="5"
            ></textarea>
            <span
              class="characters-left"
              :class="{
                error: note.text.length == note.maxlength,
              }"
              >{{ $t("adding.characters") }} :
              {{ note.maxlength - note.text.length }}</span
            >
          </div>
          <div class="modal__controls">
            <button-primary
              :onClick="
                () => {
                  closeNoteModal();
                }
              "
              :class="'small secondary black'"
              >{{ $t("adding.cancel") }}</button-primary
            >
            <button-primary
              v-if="note.remove == true"
              :onClick="
                () => {
                  removeNote(note.type, note.uid);
                }
              "
              :class="'small delete'"
            >
              <span v-if="!updateLoading">{{ $t("adding.remove") }}</span>
              <font-awesome-icon
                v-if="updateLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
            <button-primary
              :onClick="
                () => {
                  saveNote(note.type, note.uid);
                }
              "
              :disabled="note.text.length < 3"
              :class="'small'"
            >
              <span v-if="!updateLoading">{{ $t("adding.save") }}</span>
              <font-awesome-icon
                v-if="updateLoading == true"
                :icon="['fas', 'circle-notch']"
                spin
              />
            </button-primary>
          </div>
        </div>
      </div>
    </div>
    <!-- adding note modal -->
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { ContentLoader } from "vue-content-loader";
import { mapGetters } from "vuex";

import addSellerPopup from "@/components/popups/add-seller-popup.vue";
import addKeywordPopup from "@/components/popups/add-keyword-popup.vue";
import ebayCategoriesData from "@/assets/data/categories.json";
import ReachedLimitPopup from "../popups/reached-limit-popup.vue";
//import axios from 'axios';

export default {
  name: "sellersList",
  data() {
    return {
      count: 0,
      openRemoveModal: false,
      openAddSellerModal: false,
      openNoteModal: false,
      updateLoading: false,
      itemToRemove: 900,
      ebayCategories: ebayCategoriesData,
      processedCategories: [],
      openAddKeywordModal: {
        status: false,
        action: "add",
      },
      itemToRemoveName: "",
      itemToRemoveType: "",
      isLoading: true,
      mappedCategories: [],

      //Sellers
      editingSeller: false,
      newSeller: {
        name: "",
        rate: "",
        categories: null,
        itemShortDescription:false,
        extraImages: 0,
        allImages: false,
        buyButton: false,
        includeQuickBuyLink: true,
      },
      resetNewSeller: {
        name: "",
        rate: "",
        categories: null,
        itemShortDescription:false,
        extraImages: 0,
        allImages: false,
        buyButton: false,
        includeQuickBuyLink: true,
      },
      //Keywords
      newKeyword: {
        name: "",
        rate: 300,
        isFast: false,
        stopwords: [],
        categories: null,
        searchTitle: false,
        condition: 0,
        buyingFormat: 0,
        extraImages: 0,
        buyButton: false,
        allImages: false,
        itemShortDescription: false,
        includeQuickBuyLink: true,
        priceMin: "",
        priceMax: "",
        acceptsOffers: false,
      },
      resetNewKeyword: {
        name: "",
        rate: 300,
        isFast: false,
        stopwords: [],
        categories: null,
        searchTitle: false,
        condition: 0,
        buyingFormat: 0,
        extraImages: 0,
        buyButton: false,
        allImages: false,
        itemShortDescription: false,
        includeQuickBuyLink: true,
        priceMin: "",
        priceMax: "",
        acceptsOffers: false,
      },
      addSellerError: null,

      catPlaceHolder: "Any",
      stopwords: "",

      view: "all",
      rates: [1, 10, 30, 60, 120, 300, 600, 900, 1800, 3600],
      fetchedSellers: [],
      fetchedKeywords: [],
      userItemsList: [],
      addContextOpen: false,
      showReachedLimit: false,
      sortedItems: [],
      sortParam: "added",
      currentlyEditingItem: [],
      note: {
        text: "",
        maxlength: 120,
        type: "",
        name: "",
        remove: false,
      },
    };
  },
  watch: {
    newKeyword: {
      deep: true,
      handler(newVal) {
        let re = /[^\x20-\x7E]+/g;
        this.newKeyword.name = newVal.name.replace(re, "");
      },
    },
  },
  computed: {
    allowedRates() {
      var allowed = [];
      for (var i = 0; i < this.rates.length; i++) {
        if (this.rates[i] >= this.plan.keywords_rate) {
          allowed.push(this.rates[i]);
        }
      }
      return allowed;
    },
    hasItems: function() {
      var items = false;
      if (this.userSellers && this.userSellers.length > 0) {
        items = true;
      }
      if (this.userKeywords && this.userKeywords.length > 0) {
        items = true;
      }
      return items;
    },
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      plans: "plans",
      userSellers: "userSellers",
      userKeywords: "userKeywords",
    }),
    plan: function() {
      if (this.user.profile) {
        var planId = this.user.profile.plan;
        var plan;
        for (var i = 0, len = this.plans.length; i < len; i++) {
          if (this.plans[i].id == planId) {
            plan = this.plans[i];
          }
        }
        return plan;
      } else {
        return 0;
      }
    },

    filteredList() {
      // grab the array and filter
      const items = this.userItemsList.filter((item) => {
        if (this.view == "all") {
          return item;
        } else {
          return item.type == this.view;
        }
      });

      // return sorted array, updates whenever 'param' changes
      return items.sort((a, b) => {
        if (this.sortParam == "type") {
          return b.type - a.type;
        }
        if (this.sortParam == "added") {
          return b.added.seconds - a.added.seconds;
        }
        if (this.sortParam == "name") {
          return a.name.localeCompare(b.name);
        }
      });
    },
    listOfRemovedType() {
      return this.userItemsList.filter((item) => {
        return item.type == this.itemToRemoveType;
      });
    },
  },
  components: {
    ContentLoader,
    addSellerPopup,
    addKeywordPopup,
    ReachedLimitPopup,
  },
  filters: {
    dateAdded(data) {
      if (data.seconds) {
        return data.seconds;
      } else {
        return data;
      }
    },
  },
  methods: {
    doubleCheckKeywordRate(rate) {
      var finalRate = this.plan.keywords_rate;
      if (
        this.newKeyword.isFast &&
        Number(rate) == Number(this.plan.fast_keywords_rate)
      ) {
        finalRate = rate;
      } else if (!this.newKeyword.isFast && this.allowedRates.includes(rate)) {
        finalRate = rate;
      } else {
        finalRate = this.allowedRates[0];
      }
      return finalRate;
    },
    checkIfKeywordRateAllowed(rate) {
      var allowed = true;

      if (this.allowedRates.includes(rate) == false) {
        allowed = false;
      }

      return allowed;
    },
    toggleFastKeyword() {
      this.newKeyword.isFast = !this.newKeyword.isFast;

      if (this.newKeyword.isFast == true) {
        this.newKeyword.rate = Number(this.plan.fast_keywords_rate);
      } else {
        this.newKeyword.rate = Number(this.allowedRates[0]);
      }
    },
    processCategories: function() {
      var result = [];
      var count = 0;
      for (var i = 0; i < this.ebayCategories.length; i++) {
        var item = this.catNormalizer(this.ebayCategories[i]);
        count++;
        if (this.ebayCategories[i].Children) {
          item["children"] = [];
          for (var x = 0; x < this.ebayCategories[i].Children.length; x++) {
            count++;
            var children_1 = this.catNormalizer(
              this.ebayCategories[i].Children[x]
            );

            if (this.ebayCategories[i].Children[x].Children) {
              children_1["children"] = [];
              for (
                var y = 0;
                y < this.ebayCategories[i].Children[x].Children.length;
                y++
              ) {
                count++;
                var children_2 = this.catNormalizer(
                  this.ebayCategories[i].Children[x].Children[y]
                );
                children_1.children.push(children_2);
              }
            }
            item.children.push(children_1);
          }
        }
        this.count = count;
        result.push(item);
      }
      this.processedCategories = result;
    },
    quickEdit(type, uid, note) {
      this.updateLoading = true;
      if (type == "keyword") {
        for (var x = 0; x < this.fetchedKeywords.length; x++) {
          if (this.fetchedKeywords[x].uid == uid) {
            this.newKeyword = this.fetchedKeywords[x];
            if (note == true) {
              this.fetchedKeywords[x].note = this.note.text;
              this.openNoteModal = false;
              this.note.text = "";
            }
            this.addKeyword("edit");
          }
        }
      } else {
        for (var y = 0; y < this.fetchedSellers.length; y++) {
          if (this.fetchedSellers[y].uid == uid) {
            this.newSeller = this.fetchedSellers[y];
            if (note == true) {
              this.fetchedSellers[y].note = this.note.text;
              this.openNoteModal = false;
              this.note.text = "";
            }
            this.addSeller("edit");
          }
        }
      }
    },
    catNormalizer(node) {
      if (node.Children) {
        return {
          id: node.Id,
          label: node.Name,
          children: node.Children,
        };
      } else {
        return {
          id: node.Id,
          label: node.Name,
        };
      }
    },
    updateStopWords(newTags) {
      this.newKeyword.stopwords = [];
      for (var i = 0; i < newTags.length; i++) {
        const re = /[^\x20-\x7E]+/g;
        var item = newTags[i].text.replace(re, "");

        this.newKeyword.stopwords.push(item);
      }
    },
    noteModal(uid, name, type, text) {
      if (text.length) {
        this.note.text = text;
        this.note.remove = true;
      }
      this.note.uid = uid;
      this.note.name = name;
      this.note.type = type;
      this.openNoteModal = true;
    },
    saveNote(type, uid) {
      this.quickEdit(type, uid, true);
    },
    removeNote(type, uid) {
      this.note.text = "";
      this.note.remove = false;
      this.quickEdit(type, uid, true);
    },
    closeNoteModal() {
      this.openNoteModal = false;
      this.note.text = "";
      this.note.remove = false;
    },

    updateItemsInStore(type) {
      if (type == "seller") {
        this.$store.dispatch("fetchUserSellers", this.fetchedSellers);
      }
      if (type == "keyword") {
        this.$store.dispatch("fetchUserKeywords", this.fetchedKeywords);
      }
    },
    openRemovingModal(uid) {
      this.openRemoveModal = true;
      for (var i = 0; i < this.userItemsList.length; i++) {
        if (this.userItemsList[i].uid == uid) {
          this.itemToRemove = i;
        }
      }

      this.itemToRemoveName = this.userItemsList[this.itemToRemove].name;
      this.itemToRemoveType = this.userItemsList[this.itemToRemove].type;
    },
    closeRemovingModal() {
      this.openRemoveModal = false;
      this.sellerToRemove = null;
      this.updateLoading = false;
    },
    removeItem(type) {
      this.updateLoading = true;

      //Remove item from all items (all types)
      this.userItemsList.splice(this.itemToRemove, 1);

      var db = firebase.firestore();
      var userId = firebase.auth().currentUser.uid;
      var itemsRef;
      if (type == "seller") {
        itemsRef = db.collection("sellers").doc(userId);
      }
      if (type == "keyword") {
        itemsRef = db.collection("keywords").doc(userId);
      }

      //Get items left of the same type to update DB
      var updatedItems = this.listOfRemovedType;
      itemsRef
        .update({
          items: updatedItems,
        })
        .then(() => {
          this.updateLoading = false;
          this.itemToRemove = null;
          this.openRemoveModal = false;
          this.getItems();
        })
        .catch(function(error) {
          return error;
        });
    },
    getSellers: async function() {
      /* eslint-disable no-console */
      console.log("Getting sellers");
      var db = firebase.firestore();
      var userId = firebase.auth().currentUser.uid;
      var docRef = db.collection("sellers").doc(userId);

      // Get a document, forcing the SDK to fetch from the offline cache.
      await docRef
        .get()
        .then((doc) => {
          this.fetchedSellers = doc.data().items;
          this.isLoading = false;
          if (this.fetchedSellers !== null) {
            for (var i = 0; i < this.fetchedSellers.length; i++) {
              this.userItemsList.push(this.fetchedSellers[i]);
            }
          }
          this.updateItemsInStore("seller");
        })
        .catch((error) => {
          this.isLoading = false;
          this.fetchedSellers = error;
        });
    },
    getKeywords: async function() {
      /* eslint-disable no-console */
      console.log("Getting keywords");
      var db = firebase.firestore();
      var userId = firebase.auth().currentUser.uid;
      var docRef = db.collection("keywords").doc(userId);

      // Get a document, forcing the SDK to fetch from the offline cache.
      await docRef
        .get()
        .then((doc) => {
          this.fetchedKeywords = doc.data().items;
          this.isLoading = false;
          if (this.fetchedKeywords !== null) {
            for (var i = 0; i < this.fetchedKeywords.length; i++) {
              this.userItemsList.push(this.fetchedKeywords[i]);
            }
          }
          this.updateItemsInStore("keyword");
        })
        .catch((error) => {
          this.isLoading = false;
          this.fetchedKeywords = error;
        });
    },
    getItems: function() {
      this.userItemsList = [];
      this.getSellers();
      this.getKeywords();
    },
    changeView: function(data) {
      this.view = data;
    },
    openSellerEditingModal(uid) {
      this.editingSeller = true;
      var editedItem = {};
      for (var i = 0; i < this.fetchedSellers.length; i++) {
        if (this.fetchedSellers[i].uid == uid) {
          editedItem = this.fetchedSellers[i];
        }
      }
      Object.assign(this.newSeller, editedItem);
      this.openAddSellerModal = true;
    },
    openAddingModal(type, action, uid) {
      this.openAddKeywordModal.action = action;
      if (
        this.userItemsList === null ||
        (type == "seller" &&
          this.userSellers.length < this.plan.sellers_count) ||
        (type == "keyword" &&
          this.userKeywords.length < this.plan.keywords_count) ||
        action == "edit"
      ) {
        if (type == "seller") {
          this.openAddSellerModal = true;
        }
        if (type == "keyword") {
          // Clone keyword
          if (action == "clone") {
            var target;
            for (var i = 0; i < this.fetchedKeywords.length; i++) {
              if (this.fetchedKeywords[i].uid == uid) {
                target = this.fetchedKeywords[i];
                this.newKeyword.name = target.name;
                this.newKeyword.rate = target.rate;
                this.newKeyword.stopwords = target.stopwords;
                this.newKeyword.categories = parseFloat(target.categories);
                this.newKeyword.searchTitle = target.searchTitle;
                this.newKeyword.condition = target.condition;
                this.newKeyword.buyingFormat = target.buyingFormat;
                this.newKeyword.acceptsOffers = target.acceptsOffers;
                this.newKeyword.extraImages = target.extraImages;
                this.newKeyword.allImages = target.allImages;
                this.newKeyword.includeQuickBuyLink =
                  target.includeQuickBuyLink;
                this.newKeyword.priceMin = target.priceMin;
                this.newKeyword.priceMax = target.priceMax;
              }
            }
          }
          //or edit
          if (action == "edit") {
            for (var x = 0; x < this.fetchedKeywords.length; x++) {
              if (this.fetchedKeywords[x].uid == uid) {
                Object.assign(this.newKeyword, this.fetchedKeywords[x]);
              }
            }
          }
          this.openAddKeywordModal.status = true;
        } else {
          return false;
        }
      } else {
        this.showReachedLimit = true;
      }
    },
    closeAddingModal: function() {
      this.editingSeller = false;
      this.openAddSellerModal = false;
      this.openAddKeywordModal.status = false;

      this.updateLoading = false;
      this.addSellerError = null;
      Object.assign(this.newSeller, this.resetNewSeller);
      Object.assign(this.newKeyword, this.resetNewKeyword);
    },
    saveSeller(value) {
      console.log(value);
      var usecase = value.usecase;
      this.newSeller = value.newSeller;
      this.addSeller(usecase);
    },
    addSeller: async function(action) {
      this.addSellerError = null;
      var db = firebase.firestore();
      var userId = firebase.auth().currentUser.uid;
      var now = new Date();
      const sellerRef = db.collection("sellers").doc(userId);

      //Generate UID for new item
      var ID = function() {
        return (
          "_" +
          Math.random()
            .toString(36)
            .substr(2, 9)
        );
      };
      var uid = ID();
      var updatedSellers;
      console.log("action: " + action);
      if (action == "edit") {
        updatedSellers = this.fetchedSellers;
        //Finde index of item to replace
        var removeIndex;
        var targetUid = this.newSeller.uid;
        for (var i = 0; i < updatedSellers.length; i++) {
          if (updatedSellers[i].uid == targetUid) {
            removeIndex = i;
          }
        }
        updatedSellers.splice(removeIndex, 1, this.newSeller);
      } else {
        if (this.fetchedSellers !== null) {
          updatedSellers = this.fetchedSellers;
        } else {
          this.fetchedSellers = [];
          this.fetchedSellers.unshift({
            name: this.newSeller.name,
            status: 1,
            type: "seller",
            rate: this.plan.sellers_rate,
            added: now,
            uid: uid,
            note: "",
            buyButton: this.newSeller.buyButton,
            itemShortDescription:this.newSeller.itemShortDescription,
            extraImages: this.newSeller.extraImages,
            allImages: this.newSeller.allImages,
            includeQuickBuyLink: this.newSeller.includeQuickBuyLink,
            categories: this.newSeller.categories,
          });
          updatedSellers = [];
        }
        updatedSellers.unshift({
          name: this.newSeller.name,
          status: 1,
          type: "seller",
          rate: this.plan.sellers_rate,
          added: now,
          uid: uid,
          note: "",
          categories: this.newSeller.categories,
          buyButton: this.newSeller.buyButton,
          itemShortDescription:this.newSeller.itemShortDescription,
          extraImages: this.newSeller.extraImages,
          allImages: this.newSeller.allImages,
        });
      }
      await sellerRef
        .update({
          items: updatedSellers,
        })
        .then(() => {
          this.updateLoading = false;
          this.openAddSellerModal = false;
          this.newSeller.name = "";
          this.newSeller.itemShortDescription=false;
          this.newSeller.categories = null;
          this.newSeller.acceptsOffers = false;
          this.getItems();
          this.isLoading = false;
        })
        .catch(function(error) {
          return error;
        });
    },
    saveKeyword(value) {
      this.newKeyword = value.newKeyword;
      var action = value.action;
      this.addKeyword(action);
    },
    addKeyword: async function(action) {
      this.updateLoading = true;
      this.addSellerError = null;
      var db = firebase.firestore();
      var userId = firebase.auth().currentUser.uid;
      var now = new Date();
      const keywordRef = db.collection("keywords").doc(userId);
      var updatedKeywords;
      if (action == "edit") {
        updatedKeywords = this.fetchedKeywords;

        // Find old one to replace
        var targetUid = this.newKeyword.uid;
        var deleteIndex;
        for (var i = 0; i < updatedKeywords.length; i++) {
          if (updatedKeywords[i].uid == targetUid) {
            deleteIndex = i;
          }
        }

        //Replace with edited keyword
        updatedKeywords.splice(deleteIndex, 1, this.newKeyword);
        console.log(updatedKeywords);
      } else {
        //Generate UID for new item
        var ID = function() {
          return (
            "_" +
            Math.random()
              .toString(36)
              .substr(2, 9)
          );
        };
        var uid = ID();

        if (this.fetchedKeywords !== null) {
          updatedKeywords = this.fetchedKeywords;
        } else {
          this.fetchedKeywords = [];
          this.fetchedKeywords.unshift({
            name: this.newKeyword.name,
            status: 1,
            type: "keyword",
            rate: this.doubleCheckKeywordRate(this.newKeyword.rate),
            added: now,
            note: "",
            isFast: this.newKeyword.isFast,
            buyButton: this.newKeyword.buyButton,
            stopwords: this.newKeyword.stopwords,
            categories: this.newKeyword.categories,
            searchTitle: this.newKeyword.searchTitle,
            itemShortDescription: this.newKeyword.itemShortDescription,
            condition: this.newKeyword.condition,
            buyingFormat: this.newKeyword.buyingFormat,
            acceptsOffers: this.newKeyword.acceptsOffers,
            extraImages: this.newKeyword.extraImages,
            allImages: this.newKeyword.allImages,
            includeQuickBuyLink: this.newKeyword.includeQuickBuyLink,
            priceMin: this.newKeyword.priceMin,
            priceMax: this.newKeyword.priceMax,
            uid: uid,
          });
          updatedKeywords = [];
        }
        updatedKeywords.unshift({
          name: this.newKeyword.name,
          status: 1,
          type: "keyword",
          rate: this.doubleCheckKeywordRate(this.newKeyword.rate),
          isFast: this.newKeyword.isFast,
          added: now,
          note: "",
          buyButton: this.newKeyword.buyButton,
          stopwords: this.newKeyword.stopwords,
          categories: this.newKeyword.categories,
          searchTitle: this.newKeyword.searchTitle,
          itemShortDescription: this.newKeyword.itemShortDescription,
          condition: this.newKeyword.condition,
          buyingFormat: this.newKeyword.buyingFormat,
          acceptsOffers: this.newKeyword.acceptsOffers,
          extraImages: this.newKeyword.extraImages,
          allImages: this.newKeyword.allImages,
          includeQuickBuyLink: this.newKeyword.includeQuickBuyLink,
          priceMin: this.newKeyword.priceMin,
          priceMax: this.newKeyword.priceMax,
          uid: uid,
        });
      }
      await keywordRef
        .update({
          items: updatedKeywords,
        })
        .then(() => {
          this.updateLoading = false;
          Object.assign(this.newKeyword, this.resetNewKeyword);
          this.closeAddingModal();
          this.getItems();
          this.isLoading = false;
        })
        .catch(function(error) {
          return error;
        });
    },
  },
  mounted() {
    this.processCategories();
    // this.getEbayCategories();
  },
  beforeMount() {
    this.getItems();
  },
};
</script>
<style lang="scss">
@import "@/scss/_tree-select.scss";
.no-items {
  padding: 30px 20px;
  color: #666;
  .image {
    margin-top: 25px;
  }
}
.characters-left {
  margin-top: 10px;
  display: block;
  font-size: 13px;
  color: #666;

  &.error {
    color: #f00;
  }
}
.form-group {
  &.keyword-price {
    display: flex;
    justify-content: space-between;

    .w-50 {
      width: 50%;
      min-width: 50%;
      box-sizing: border-box;

      &:nth-child(1) {
        padding-right: 30px;
      }
    }

    input {
      max-width: 100% !important;
      min-width: 100% !important;
      width: initial;
    }
  }
}

.sellersList {
  padding: 15px;
}

.coming-soon {
  padding: 30px;
  font-size: 22px;
  color: #ccc;
  text-align: center;
}

.table-head,
.table-row {
  font-size: 14px;
  display: flex;
  padding: 12px 0px;
  border-bottom: 1px solid #e7e7e7;
  align-items: center;
  transition: 0.3s;

  .clickable {
    cursor: pointer;
  }

  &.disabled {
    color: rgba(#000, 0.3);
    transition: 0.3s;

    .name {
      span {
        color: #666 !important;
        background: rgba(#000, 0.1) !important;
      }
    }
  }

  .order {
    width: 30px;
    min-width: 30px;
    padding-left: 10px;
    color: rgba(#000, 0.3);
  }

  .note {
    width: 70px;
    min-width: 60px;

    .note-icon {
      color: #ffb351;
      font-size: 16px;
      cursor: pointer;
    }

    .info-icon {
      color: #20a8f8;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .name {
    width: 160px;
    min-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 5px;
    box-sizing: border-box;

    span {
      display: inline-block;
      padding: 3px 8px;
      background: rgba(#f60, 0.1);
      color: darken(#f60, 10%);
      border-radius: 3px;

      &.keyword {
        color: darken(#9100ff, 10%);
        background: rgba(#9100ff, 0.1);
      }
    }
  }

  .type {
    width: 80px;
    min-width: 80px;

    span {
      color: #999;
    }
  }

  .status {
    width: 80px;
    min-width: 80px;
  }

  .added {
    width: 160px;
    min-width: 160px;
  }

  .rate {
    width: 90px;
    min-width: 90px;

    span {
      color: #34b98e;
    }

    @media (max-width: 768px) {
      width: 110px;
      min-width: 110px;
    }

    select {
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .controls {
    margin-left: auto;
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    a {
      display: inline-flex;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      margin-left: 6px;

      &.link {
        color: #207ed4;
      }

      &.clone {
        color: #424242;
      }

      &.remove {
        color: #f00;
      }

      &.edit {
        color: #f60;
      }

      &:hover {
        background-color: rgba(#888, 0.1);
        transition: 0.3s;
      }
    }
  }

  strong {
    font-weight: 600;
  }
}

.tabs-content {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  &.loading {
    .loading-overlay {
      position: absolute;
      display: flex;
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
      z-index: 20;
      background: rgba(#fff, 0.5);
      font-size: 64px;
      align-items: center;
      justify-content: center;
      color: #bce5fd;
      svg {
        fill: #08a6fb;
        height: 64px;
        width: 64px;
      }
    }
  }

  @media (max-width: 1024px) {
    overflow-x: scroll;
  }

  *::-webkit-scrollbar {
    width: 1em;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  *::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.tabs-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .button {
    & > svg {
      margin-left: 10px;
    }
  }
}

.v-select {
  ::-webkit-input-placeholder {
    color: #757575;
  }

  :-ms-input-placeholder {
    color: #757575;
  }

  ::placeholder {
    color: #757575;
  }
}

.nav-tabs {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;

  a {
    font-weight: normal;
    font-size: 18px;
    color: rgba(#000000, 0.4);
    display: inline-flex;
    height: 42px;
    padding: 10px 20px;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    align-items: center;
    transition: 0.3s;

    @media (max-width: 768px) {
      padding: 10px 15px;
      font-size: 16px;
    }

    &:hover {
      color: #000;
      transition: 0.3s;
    }

    &.active {
      color: #000;
      border-bottom: 3px solid #08a6fb;
      transition: 0.3s;
    }
  }
}
</style>
