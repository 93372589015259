<template>
  <!-- ADDING SELLER -->
  <div class="modal" v-if="open == true">
    <div class="modal__wrapper">
      <div class="card">
        <div class="modal__title" v-if="!edit">
          + {{ $t("list.btn_add_seller") }}
        </div>
        <div class="modal__title" v-else>
          <font-awesome-icon :icon="['fa', 'pencil-alt']" />
          {{ $t("adding.edit") }} {{ $t("adding.seller") }}
        </div>
        <div class="form-group">
          <label>{{ $t("adding.seller_id") }}</label>
          <input
            type="text"
            ref="input"
            pattern="[A-Za-z]"
            v-model="newSeller.name"
            v-on:keyup.enter="checkIfSellerExists"
          />
          <div v-if="addSellerError" class="alert alert-danger">
            {{ addSellerError }}
          </div>
        </div>
        <div class="form-group" v-if="user.profile.plan != 0">
          <label
            >{{ $t("adding.seller_category_title") }} ({{
              $t("adding.optional")
            }})</label
          >
          <treeselect
            v-model="newSeller.categories"
            :multiple="false"
            :options="processedCategories"
            :searchable="false"
            :noChildrenText="'No child categories'"
          />
        </div>
        <div class="form-group">
          <label>Search short description</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.itemShortDescription" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t("adding.checkout_link") }}</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.includeQuickBuyLink" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group" v-if="plan && plan.buyButton == true">
          <label>Buy button</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.buyButton" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t("adding.all_images") }}</label>
          <div class="ui-checkbox">
            <label>
              <input type="checkbox" v-model="newSeller.allImages" />
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group" v-show="!newSeller.allImages">
          <label>{{ $t("adding.extra_images") }} </label>
          <div class="ui-radio-group">
            <label v-for="(n, i) in 4" :key="i">
              <input
                type="radio"
                :value="i"
                checked
                v-model="newSeller.extraImages"
              />
              <span>{{ i }}</span>
            </label>
          </div>
        </div>
        <div class="modal__controls">
          <button-primary :onClick="emitClose" :class="'small secondary'">{{
            $t("adding.cancel")
          }}</button-primary>
          <button-primary
            :disabled="newSeller.name.length < 3"
            :onClick="checkIfSellerExists"
            :class="'small'"
          >
            <span v-if="!updateLoading">{{ $t("adding.save") }}</span>
            <font-awesome-icon
              v-if="updateLoading == true"
              :icon="['fas', 'circle-notch']"
              spin
            />
          </button-primary>
        </div>
      </div>
    </div>
  </div>
  <!-- /addingSellerModal -->
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import Treeselect from "@riophae/vue-treeselect";
export default {
  props: ["open", "plan", "newSeller", "edit", "processedCategories"],
  components: {
    Treeselect,
  },
  data() {
    return {
      addSellerError: null,
      updateLoading: false,
    };
  },
  watch: {
    open: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal == true) {
          this.$nextTick(() => {
            this.$refs.input.focus();
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      plans: "plans",
      userSellers: "userSellers",
      userKeywords: "userKeywords",
    }),
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    checkIfSellerExists() {
      if (this.newSeller.name.length < 2) {
        this.addSellerError = "Too short seller ID";
      } else {
        this.updateLoading = true;
        var checkIfSeller = firebase.functions().httpsCallable("checkSeller");
        checkIfSeller({ text: this.newSeller.name }).then((result) => {
          var testResult = JSON.parse(result.data);
          var isReal = testResult.findItemsAdvancedResponse[0].ack[0];

          if (isReal.toLowerCase().indexOf("success") > -1) {
            var usecase = "add";
            if (this.edit == true || this.edit == "true") {
              usecase = "edit";
            }
            this.$emit("save", { usecase: usecase, newSeller: this.newSeller });
            this.updateLoading = false;
          } else {
            this.addSellerError = "Seller doesn't exist";
            this.updateLoading = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
