<template>
	<div class="home">
		<vue-headful
			:title="[$t('menu.monitoring')] + '- UNDERMRKT'"
			description="Description from vue-headful"
		/>
		<div class="container">
			<h1>
				<div class="icon">
					<font-awesome-icon :icon="['fas', 'binoculars']" />
				</div>
				{{ $t("menu.monitoring") }}
			</h1>

			<div class="row">
				<div class="content">
					<template v-if="user">
						<div
							class="notifications-informer"
							v-if="
								user.profile &&
									user.profile.telegram !== 'true' &&
									user.profile.facebook !== 'true' &&
									hasItems
							"
						>
							<div class="left">
								<i>⚠️</i>
								<strong>
									{{ $t("list.congratulations") }}! </strong
								>{{ $t("list.notifications_setup_alert") }}
							</div>
							<div class="right">
								<router-link
									class="button small"
									:to="{ name: 'notifications' }"
								>
									{{ $t("sidebar.manage_notifications") }}
								</router-link>
							</div>
						</div>
					</template>
					<div class="card">
						<sellersList></sellersList>
					</div>
				</div>
				<sidebar>
					<template v-slot:title>{{
						$t("menu.monitoring")
					}}</template>
					<template v-slot:text>{{
						$t("list.description")
					}}</template>
				</sidebar>
			</div>
		</div>
	</div>
</template>
<script>
// @ is an alias to /src
import sidebar from "@/components/Sidebar.vue";
import sellersList from "@/components/features/SellersList.vue";
import { mapGetters } from "vuex";
export default {
	name: "home",
	computed: {
		...mapGetters({
			user: "user",
			userSellers: "userSellers",
			userKeywords: "userKeywords",
		}),
		hasItems: function() {
			var has = false;
			if (this.userSellers && this.userSellers.length > 0) {
				has = true;
			}
			if (this.userKeywords && this.userKeywords.length > 0) {
				has = true;
			}

			return has;
		},
	},
	components: {
		sidebar,
		sellersList,
	},
	beforeCreate: function() {
		document.body.className = "monitoring";
	},
};
</script>
<style lang="scss" scoped>
h1 {
	.icon {
		background: #00a3ff !important;
	}
}
.notifications-informer {
	i {
		font-style: normal;
		font-size: 20px;
		display: inline-block;
		margin-right: 10px;
		position: absolute;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		@media (max-width: 768px) {
			transform: none;
			top: 10px;
			right: 10px;
			left: initial;
			font-size: 28px;
		}
	}
	.button {
		min-width: 220px;
	}
	strong {
		@media (max-width: 768px) {
			display: block;
			font-size: 18px;
		}
	}
	.left {
		padding-right: 20px;
		@media (max-width: 768px) {
			padding-right: 0px;
			margin-bottom: 20px;
		}
	}
	.right {
		@media (max-width: 768px) {
			margin-right: auto;
		}
	}
	position: relative;

	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 768px) {
		flex-direction: column;
		padding-left: 10px;
		padding-right: 60px;
		font-size: 16px;
	}
	padding: 10px 10px;
	padding-left: 50px;
	border-radius: 8px;
	margin-bottom: 15px;
	background: #ff416c; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to right,
		#ff4b2b,
		#ff416c
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#ff4b2b,
		#ff416c
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff;
}
</style>
